import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { get } from "lodash/fp";
import { Container, Section } from "../../components/LayoutUtils";
import SEO from "../../components/SEO";
import IntroHeader from "../../components/IntroHeader";
import { IMAGE_POSITIONS } from "../../utils/imagePosition";
import TeamMemberList from "../../components/TeamMemberList";
import Layout from "../../layouts/index";
import getPageImage from "../../utils/getPageImage";

const Team = ({ data }) => {
  const teams = data.allContentfulTeamMember.edges;
  const page = data.contentfulPage;
  return (
    <Layout>
      <SEO
        image={getPageImage(page)}
        title="Team"
        description="Our team has over 75 years of combined experience. Whatever it is you need, we can tailor it for you."
      />
      <IntroHeader {...page} imagePosition={IMAGE_POSITIONS.TOP} />
      <Container>
        <Section>
          <div className="row">
            <TeamMemberList teamMembers={teams.map(get("node"))} />
          </div>
        </Section>
      </Container>
    </Layout>
  );
};

Team.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  query TeamQuery {
    contentfulPage(slug: { eq: "team" }) {
      ...page
    }
    ...allContentfulTeamMembers
  }
`;

export default Team;
