import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { TeamMemberProps } from "../../proptypes";
import TeamMember from "./TeamMember";

const TeamMemberList = ({ teamMembers }) => (
  <Fragment>
    {teamMembers.map(teamMember => (
      <TeamMember key={teamMember.id} teamMember={teamMember} />
    ))}
  </Fragment>
);

TeamMemberList.propTypes = {
  teamMembers: PropTypes.arrayOf(TeamMemberProps)
};

TeamMemberList.defaultProps = {
  teamMembers: []
};

export default TeamMemberList;
