import React from "react";
import Image from "gatsby-image";
import Markdown from "../Markdown";
import { TeamMemberProps } from "../../proptypes";
import styles from "./style.module.scss";

const TeamMember = ({ teamMember }) => {
  return (
    <div className="col-md-6">
      <div className={styles.teamMember}>
        <div className={styles.teamMemberHeader}>
          <div className="card-header-left">
            {teamMember.photo && (
              <div className="card-image">
                <Image
                  alt={`${teamMember.name} - ${teamMember.jobTitle}`}
                  fluid={teamMember.photo.fluid}
                  className={styles.teamMemberImage}
                />
              </div>
            )}
          </div>
          <div className="card-right">
            <h2 className="card-title">{teamMember.name}</h2>
            <ul className="card-meta">
              <li>
                <strong>{teamMember.jobTitle}</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="team-content">
          <Markdown source={teamMember.biography.biography} />
        </div>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  teamMember: TeamMemberProps.isRequired
};

export default TeamMember;
